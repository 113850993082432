body {
  margin: 0;
  font-family: "Roboto", "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Ubuntu", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans",
    "sans-serif", "Poppins", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
